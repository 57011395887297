import { default as crazy_45modeGCqdfGAjUOMeta } from "/Users/federicaprincipe/IdeaProjects/Paradigma/pdg-website/pages/crazy-mode.vue?macro=true";
import { default as indexQmKLoFRLa8Meta } from "/Users/federicaprincipe/IdeaProjects/Paradigma/pdg-website/pages/index.vue?macro=true";
export default [
  {
    name: crazy_45modeGCqdfGAjUOMeta?.name ?? "crazy-mode___en",
    path: crazy_45modeGCqdfGAjUOMeta?.path ?? "/en/crazy-mode",
    meta: crazy_45modeGCqdfGAjUOMeta || {},
    alias: crazy_45modeGCqdfGAjUOMeta?.alias || [],
    redirect: crazy_45modeGCqdfGAjUOMeta?.redirect || undefined,
    component: () => import("/Users/federicaprincipe/IdeaProjects/Paradigma/pdg-website/pages/crazy-mode.vue").then(m => m.default || m)
  },
  {
    name: crazy_45modeGCqdfGAjUOMeta?.name ?? "crazy-mode___it",
    path: crazy_45modeGCqdfGAjUOMeta?.path ?? "/crazy-mode",
    meta: crazy_45modeGCqdfGAjUOMeta || {},
    alias: crazy_45modeGCqdfGAjUOMeta?.alias || [],
    redirect: crazy_45modeGCqdfGAjUOMeta?.redirect || undefined,
    component: () => import("/Users/federicaprincipe/IdeaProjects/Paradigma/pdg-website/pages/crazy-mode.vue").then(m => m.default || m)
  },
  {
    name: indexQmKLoFRLa8Meta?.name ?? "index___en",
    path: indexQmKLoFRLa8Meta?.path ?? "/en",
    meta: indexQmKLoFRLa8Meta || {},
    alias: indexQmKLoFRLa8Meta?.alias || [],
    redirect: indexQmKLoFRLa8Meta?.redirect || undefined,
    component: () => import("/Users/federicaprincipe/IdeaProjects/Paradigma/pdg-website/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexQmKLoFRLa8Meta?.name ?? "index___it",
    path: indexQmKLoFRLa8Meta?.path ?? "/",
    meta: indexQmKLoFRLa8Meta || {},
    alias: indexQmKLoFRLa8Meta?.alias || [],
    redirect: indexQmKLoFRLa8Meta?.redirect || undefined,
    component: () => import("/Users/federicaprincipe/IdeaProjects/Paradigma/pdg-website/pages/index.vue").then(m => m.default || m)
  }
]