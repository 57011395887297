<script setup lang="ts">
    //import Lenis from '@studio-freight/lenis';
    import gsap from "gsap";
    import { ScrollTrigger } from "gsap/all";

    const { locale } = useI18n();

    useHead({
        htmlAttrs: {
            lang: locale.value,
        },
    });

    onMounted(() => {
        nextTick(() => {
            gsap.registerPlugin(ScrollTrigger);
            const userAgent = window.navigator.userAgent;

            let startSize = {
                width: window.outerWidth,
                height: window.outerHeight,
            };
            // const mm = gsap.matchMedia(), breakPoint = 1024;
            //     mm.add(
            //         {
            //         isDesktop: `(min-width: ${breakPoint}px)`,
            //         isMobile: `(max-width: ${breakPoint - 1}px)`,
            //         }, ({conditions}) => {
            //             const { isDesktop } = conditions as gsap.Conditions;

            //             if (isDesktop) {

            //                  //SMOOTH SCROLL DI LENIS https://github.com/studio-freight/lenis

            //                 const lenis = new Lenis({
            //                     duration: 4,
            //                     //versione adattata dell'easing "easeOutExpo" -> t === 1 ? 1 : 1 - Math.pow(2, -10 * t)
            //                     easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
            //                 });

            //                 lenis.on('scroll', ScrollTrigger.update);

            //                 gsap.ticker.add((time) => {
            //                     lenis.raf(time * 1000);
            //                 });

            //             } else {
            //                 const lenis = new Lenis({
            //                     duration: 6,
            //                     //versione adattata dell'easing "easeOutExpo" -> t === 1 ? 1 : 1 - Math.pow(2, -10 * t)
            //                     easing: (t) => Math.min(1, 1.101 - Math.pow(2, -10 * t)),
            //                 });

            //                 lenis.on('scroll', ScrollTrigger.update);

            //                 gsap.ticker.add((time) => {
            //                     lenis.raf(time * 1000);
            //                 });
            //             }
            //         });

            // gsap.ticker.lagSmoothing(0);

            const noscript = document.createElement('noscript')
            noscript.innerHTML = `
            <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-T9GFVL74"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>
            `
            document.body.appendChild(noscript)
            

            window.addEventListener("resize", () => {
                if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
                    if (
                        startSize.width !== window.outerWidth ||
                        startSize.height !== window.outerHeight
                    ) {
                        startSize = {
                            width: window.outerWidth,
                            height: window.outerHeight,
                        };

                        // gsap.matchMediaRefresh();
                        ScrollTrigger.refresh(true);
                    }
                }
                else {
                    // gsap.matchMediaRefresh();
                    ScrollTrigger.refresh(true);
                }
            });
        })
    });
</script>

<template>
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>
</template>

<style lang="scss">
    $grid-gutter-width: 1rem;

    $grid-breakpoints: (
        xs: 0,
        sm: 768px,
        md: 1024px,
        lg: 1440px,
        xl: 1920px,
    );

@import "./node_modules/bootstrap/scss/bootstrap.scss";
@import "scss/includes.scss";
@import "scss/_typography.scss";

    body {
        font-family: "PP Neue Montreal", sans-serif;
        overflow-x: hidden;

        .pdg-line {
            overflow: hidden;
        }
    }

    html {
        overflow: hidden;
        overscroll-behavior: none;

        &.page-scroll {
            overflow-y: auto;
        }
    }

    :root {
        --white: #ffffff;
        --dark-blue: #0a2843;
        --blue: #246399;
        --light-blue: #63a4d8;
        --green: #c3f73a;
        --pink: #ffc0d5;
        --dark-pink: #ee4266;
        --white-smoke: #f0f0f0;
    }

    :focus-visible {
        outline: none;
    }

    strong { 
      font-weight: 500;
    }

    .pdg-list-menu-header {
        padding: 0;
        list-style: none;
        margin-bottom: 0;
    }
</style>
