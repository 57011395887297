
// https://router.vuejs.org/api/interfaces/routeroptions.html
/**
 * @see https://github.com/nuxt/framework/discussions/5561#discussioncomment-3411408
 */

import type { RouterConfig } from '@nuxt/schema';
import { useRouter } from 'nuxt/app'

const activeClass = 'router-link-active';
const exactActiveClass = 'router-link-exact-active';

export default <RouterConfig>{
  // scroll to hash, useful for using to="#some-id" in NuxtLink
  // ex: <NuxtLink to="#top"> To Top </ NuxtLink>
  scrollBehavior: (to, from, savedPosition) => {
    const router = useRouter();
    
    if (process.client) {
        const nuxtLinks = document.querySelectorAll(`.${activeClass}`);
        nuxtLinks.forEach(
          (el) => {
            if (!(<HTMLAnchorElement>el).href.endsWith(to.fullPath)) {
              el.className = el.className.replaceAll(exactActiveClass, '')
            } else {
              el.classList.add(exactActiveClass);
            }
          }
        );
    
        if (to.hash) {
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve({
                el: to.hash,
                behavior: 'smooth',
              });
            }, 500);
          });
        }
    
        // If link is to same page, scroll to top with smooth behavior
        if (to === from) {
          return {
            left: 0,
            top: 0,
            behavior: 'smooth',
          };
        }
    
        //This will use saved scroll position on browser forward/back navigation
        return new Promise((resolve) => {
          setTimeout(() => {
            resolve({
              left: 0,
              top: 0,
              behavior: 'instant'
            });
          }, 100);
       });
    }
  }
};
