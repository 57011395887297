<template>
    <div class="content-progress-bar">
        <div class="progress-bar" id="progress-bar"></div>
    </div>
</template>

<style scoped lang="scss">
    @import "../scss/includes.scss";

    .content-progress-bar {
        background-color: rgba(215, 215, 215, 0.80);
        margin-top: rem(12);
        &,
        .progress-bar {
            height: rem(3);
        }

        @media screen and (min-width: map-get($breakpoints, md)) {
            // Lo nascondo con il css e non con il v-if per evitare che al caricamento della pagina si vede la progress bar da dektop
            display: none;
         }

        .progress-bar {
            transition: width, color 0.3s ease-in-out;
            width: 0;
            background-color: currentColor;
        }
    }
</style>
