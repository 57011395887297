<script setup lang="ts">
    import gsap from "gsap";

    const show = ref<boolean>(true);
    let ctx = ref<gsap.Context>();
    const emit = defineEmits(['show-change']);

    onMounted(() => {
        nextTick(() => {
            const tl = gsap.timeline();

            const wrapperLoader = document.querySelector(".wrapper-loader") as HTMLElement;

            if (wrapperLoader) {
                const path = wrapperLoader.querySelector(".path");
                const start = "M 0 100 V 50 Q 50 0 100 50 V 100 z";
                const end = "M 0 100 V 0 Q 50 0 100 0 V 100 z";
                const getLogo = wrapperLoader.querySelectorAll(".logo") as NodeListOf<HTMLImageElement>;

                ctx.value = gsap.context(() => {
                    tl.to(path, {
                        duration: 0.8,
                        attr: {
                            d: start,
                        },
                        ease: "power2.easeIn",
                    })
                    .to(path, {
                        duration: 0.8,
                        attr: {
                            d: end,
                        },
                        ease: "power2.easeOut",
                        onComplete: () => {
                            gsap.to(wrapperLoader, {
                                opacity: 0,
                                duration: 0.3,

                                onComplete: () => {
                                    show.value = false;
                                    emit('show-change', show.value);
                                },
                            });

                            getLogo?.forEach((img) => img.classList.add("paused"));
                        },
                    })
                    .from(
                        path,
                        {
                            y: 100,
                        },
                        "-=.8"
                    )
                    .play(0);
                });
            }
        })
    });

    onUnmounted(() => {
        if (ctx.value) {
            ctx.value.revert();
        }
    });
</script>

<template>
    <Transition v-show="show">
        <div class="wrapper-loader">
            <svg class="transition" viewBox="0 0 100 100" preserveAspectRatio="none">
                <path
                    class="path"
                    fill="white"
                    stroke="none"
                    stroke-width="2px"
                    vector-effect="non-scaling-stroke"
                    d="M 0 100 V 100 Q 50 100 100 100 V 100 z"
                />
            </svg>
            <ul class="container-logo">
                <li class="item">
                    <NuxtImg class="logo" src="/assets/svg/a4.svg" alt="asterisk" loading="lazy" />
                </li>
                <li class="item">
                    <NuxtImg class="logo" src="/assets/svg/a3.svg" alt="asterisk" loading="lazy" />
                </li>
                <li class="item">
                    <NuxtImg class="logo" src="/assets/svg/a2.svg" alt="asterisk" loading="lazy" />
                </li>
                <li class="item">
                    <NuxtImg class="logo" src="/assets/svg/a1.svg" alt="asterisk" loading="lazy" />
                </li>
            </ul>
        </div>
    </Transition>
</template>

<style scoped lang="scss">
    @import "../scss/includes.scss";

    .wrapper-loader {
        height: 100vh;
        width: 100vw;
        background-color: var(--dark-blue);
        z-index: 9999;
        position: fixed;
        inset: 0;

        &,
        .transition {
            inset: 0;
        }

        .transition {
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 10;
        }

        .container-logo {
            list-style: none;
            width: 100%;
            height: 100%;

            .item {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);

                .logo {
                    width: rem(64);
                    height: rem(64);
                    animation: spin 6s infinite ease-in-out;
                    transform: scale(0);

                    @media screen and (min-width: map-get($breakpoints, md)) {
                        width: 100%;
                        height: 100%;
                    }
                }

                &:nth-child(2) .logo {
                    animation-delay: 1400ms;
                }

                &:nth-child(3) .logo {
                    animation-delay: 2800ms;
                }

                &:nth-child(4) .logo {
                    animation-delay: 4200ms;
                }

                .paused {
                    animation-play-state: paused;
                }
            }
        }
    }

    @keyframes spin {
        0% {
            transform: scale(0) rotate(0deg);
        }

        12% {
            transform: scale(1) rotate(90deg);
        }

        25% {
            transform: scale(0) rotate(90deg);
        }
    }
</style>
