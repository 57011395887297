<script setup lang="ts">
    const localePath = useLocalePath();
    defineProps<{pathImg: boolean}>();
    const pathLink = ref<string>('menu');

    const { locale } = useI18n();
    const globalState = useGlobalState();

    const { data: translations } = await useAsyncData('translations', async () => {
        const res = await $fetch<any>(`/api/translations?lang=${locale.value}&mode=${globalState.value.mode}`)
        return res
    }, { watch: [locale, globalState] })
</script>

<template>
    <ul
        class="content-item-links pdg-list-menu-header"
        :class="{'item-links-mobile': pathImg, 'pdg-menu-item': !pathImg}">
        <template
            v-for="(_, index) in Array.from(Array(5))"
            :key="index"
        >
            <li 
                class="item-link">
                <NuxtLink
                    :noRel="true"
                    :to="{ hash: translations?.[pathLink]?.[index]?.link }"
                    class="pdg-link-menu-header"
                    :class="{
                        'change-mode': index === Array(5).length - 1 && !pathImg,
                        'link-menu-vertical': pathImg,
                        'active-link':
                            translations?.[pathLink]?.[index]?.link === $route.hash,
                    }"

                    v-if="(translations?.[pathLink]?.[index]?.link).includes('#')"
                >
                    <NuxtImg
                        v-if="pathImg"
                        :src="translations?.[pathLink]?.[index]?.['img-menu']"
                        :alt="translations?.[pathLink]?.[index]?.['img-alt']"
                        class="pdg-img"
                        loading="lazy"
                        sizes="300px"
                    />
                    <i  v-if="pathImg"
                        class="icon-arrow-left icon-link-menu"
                    ></i>
                    <span 
                        class="label-link"
                        :class="{'label-tooltip': index === Array(5).length - 1 && !pathImg}"
                        :aria-hidden="index === Array(5).length - 1 && !pathImg">
                        {{ translations?.[pathLink]?.[index]?.label }}
                    </span>
                    <i
                        :aria-label="translations?.[pathLink]?.[index]?.label"
                        :aria-hidden="!(index === Array(5).length - 1 && !pathImg)"
                        v-if="index === Array(5).length - 1 && !pathImg"
                        class="icon-brain content-icon-mode"
                    >
                    </i>
                </NuxtLink>
                <a
                    :noRel="true"
                    :href="localePath(translations?.[pathLink]?.[index]?.link)"
                    class="pdg-link-menu-header"
                    :class="{
                        'change-mode': index === Array(5).length - 1 && !pathImg,
                        'link-menu-vertical': pathImg,
                        'active-link':
                            
                            translations?.[pathLink]?.[index]?.link === $route.hash,
                    }"
                    :aria-label="(index === Array(5).length - 1 && !pathImg) ? translations?.[pathLink]?.[index]?.label : null"
                    v-else
                >
                    <NuxtImg
                        v-if="pathImg"
                        :src="translations?.menu?.[index]?.['img-menu']"
                        :alt="translations?.menu?.[index]?.['img-alt']"
                        class="pdg-img test"
                        loading="lazy"
                        sizes="200px"
                    />
                    <i  v-if="pathImg"
                        class="icon-arrow-left icon-link-menu"
                    ></i>
                    <span 
                        class="label-link"
                        :class="{'label-tooltip': index === Array(5).length - 1 && !pathImg}"
                        :aria-hidden="index === Array(5).length - 1 && !pathImg">
                        {{ translations?.[pathLink]?.[index]?.label }}
                    </span>
                    <i
                        :aria-hidden="!(index === Array(5).length - 1 && !pathImg)"
                        v-if="index === Array(5).length - 1 && !pathImg"
                        class="icon-brain content-icon-mode"
                    >
                    </i>
                </a>
            </li>
            <li
             v-if="!pathImg && index === Array(5).length - 2"
             class="pdg-item-button-menu item-link"
             >
             <button
                 class="pdg-open-menu-desktop pdg-link-menu-header"
                 type="button"
                 @click="$emit('toggleMenu')">
                <span 
                    class="label-link label-button"
                    id="label-button">
                    menu
                </span>
             </button>
            </li> 
        </template>
    </ul>
</template>

<style scoped lang="scss">
    @import "../scss/includes.scss";

    .content-item-links {
        @include typography(32, 400, 40, 0, uppercase, $vh: "true");
        @media screen and (min-width: map-get($breakpoints, md)) {
            @include typography(72, 400, 72, 0, uppercase, $vh: "true");
        }

        @media screen and (min-width: map-get($breakpoints, md)) {
            @include typography(72, 400, 72, 0, uppercase, $vh: "true");
        }

        @media screen and (min-width: map-get($breakpoints, lg)) {
            @include typography(64, 400, 64, 0, uppercase, $vh: "true");
        }

        .item-link {
            display: flex; // area icon arrow
            border-bottom: rem(1) solid var(--light-blue);
            &:not(:last-child) {
                margin-bottom: rem(24);
            }

            .pdg-link-menu-header {
                text-transform: uppercase;
                color: var(--body-13);
                text-decoration: none;
                @include flex($align-items: center, $display: inline-flex);
                outline: none;
                position: relative;
                width: 100%;
                transition: $transition;

                .label-link {
                    display: inline-block;
                    width: 100%;
                    pointer-events: none; // lo aggiungo perché l'animazione dell'immagine mentre si muove il mouse ha dei micro lag
                }

                .pdg-img {
                    position: absolute;
                    z-index: -1;
                    height: rem(300);
                    width: rem(300);
                    object-fit: contain;
                    top: 50%;
                    left: 0;
                    transform: translateY(-50%) scale(0);
                    opacity: 0;
                    visibility: hidden;
                }

                .icon-link-menu {
                    transition: font-size 0.3s ease-in-out;
                    @include typography(0, 500, 38, 0, uppercase, $vh: "true");
                    @media screen and (min-width: map-get($breakpoints, md)) {
                        @include typography(0, 500, 88, 0, uppercase, $vh: "true");
                    }
                }

                &:hover,
                &:focus,
                &:focus-visible,
                &.active-link {
                    color: var(--body-14);

                    @media screen and (min-width: map-get($breakpoints, md)) {
                        .pdg-img {
                            opacity: 1;
                            visibility: visible;
                            transform: translate(-50%, -50%) scale(1);
                        }
                    }

                    .icon-link-menu {
                        margin-right: rem(16);
                        @include typography(38, 500, 38, 0, uppercase, $vh: "true");
                        @media screen and (min-width: map-get($breakpoints, md)) {
                            @include typography(88, 500, 88, 0, uppercase, $vh: "true");
                        }
                    }
                }

                &.active-link {
                    color: var(--body-9);

                    //TODO Capire come rimuovere il listener
                    .pdg-img {
                        //TODO temporaneamente nascosta fino a quando non si capirà come rimuovere i listener
                        display: none;
                    }
                }
            }
        }
    }
</style>
