// export const mode = ref("normal");

// export const setMode = (value: "normal" | "crazy") => (mode.value = value);

import { useState } from '#app';

export const useGlobalState = () => {
  // Lo stato è una variabile reattiva globale accessibile in tutta l'app
  return useState('globalState', () => ({
    mode: 'normal',
  }));
};