<script setup lang="ts">
    import gsap from "gsap";

    const isShow = ref<boolean>(true);
    const handleShowChange = (newValue: boolean) => {
        isShow.value = newValue;
    };

    watchEffect(() => {
        if (process.client) {
            const htmlPage = document.querySelector("html") as HTMLHtmlElement;
            if ( htmlPage && !isShow.value) {
                htmlPage.classList.add("page-scroll");
            }
        }
    })

    onMounted(() => {
        if (process.client) {
            nextTick(() => {
            //CONTAINER
            const container = document.querySelector(
                ".pdg-error-container"
            ) as HTMLElement;

            //BODY OVERFLOW
            (document.querySelector("body") as HTMLElement).style.overflow = "initial";

            if (container) {
                //START ANIMATION TIMELINE
                const startAnimation = gsap
                    .timeline()
                    .fromTo(
                        ".fade-in",
                        { opacity: 0, y: 20 },
                        {
                            opacity: 1,
                            y: 0,
                            stagger: 0.1,
                        },
                        "entry"
                    )
                    .fromTo(
                        ".fade",
                        { opacity: 0 },
                        {
                            opacity: 1,
                        },
                        "entry"
                    );

                //ANIMATION DELAY
                animationDelay(startAnimation, container);

                //TEXT ANIMATION
                textAnimation({
                    tl: startAnimation,
                    split: true,
                    container: ".pdg-error-container",
                    elements: [".pdg-error-title"],
                    from: { yPercent: 100 },
                    to: { yPercent: 0 },
                    keyframe: "entry",
                });

                //CHIP ANIMATION
                chipAnimation({
                    tl: startAnimation,
                    container: ".pdg-error-container",
                    keyframe: "entry",
                });
            }
        })
        }
    });
</script>

<template>
    <NuxtLayout name="404">
        <HeadTags
            :title="$t('normal.titleClient')"
            :description="$t('normal.description')"
            :url="$t('normal.url')"
            :type="$t('normal.type')"
        />
        <Loader @show-change="handleShowChange"/>
        <Menu hideMenu/>

        <!-- CONTAINER -->
        <div class="pdg-error-container pdg-container normal-mode">
            <!-- CONTAINER ROW -->
            <div class="row pdg-error-container-row">
                <!-- INFO CONTAINER -->
                <div
                    class="pdg-error-info-container col-12 col-sm-8 col-md-6 offset-sm-1 offset-md-0"
                >
                    <!-- CHIP -->
                    <Chip :title="$t('normal.404.chip-title')" class="green fade-in" />

                    <!-- TITLE -->
                    <h1 class="pdg-error-title pdg-title-h1">
                        {{ $t("normal.404.title") }}
                    </h1>

                    <!-- DESCRIPTION -->
                    <p
                        class="pdg-error-description pdg-body-2 fade-in"
                        v-html="$t('normal.404.description')"
                    />

                    <!-- ARROW LINK -->
                    <ArrowLink
                        href="/"
                        :text="$t('normal.404.go-back')"
                        class="fade-in"
                    />

                    <!-- ASTERISKS CONTAINER -->
                    <div class="asterisks-container">
                        <!-- ASTERISKS -->
                        <client-only>
                            <Vue3Lottie
                                animationLink="/assets/lottie/asterisk.json"
                                class="asterisks fade-in"
                            />
                        </client-only>
                    </div>
                </div>

                <!-- IMAGE CONTAINER -->
                <div class="pdg-error-image-container col-10 col-sm-9 col-md-5">
                    <!-- IMAGE -->
                    <img
                        class="pdg-error-image fade"
                        src="/assets/img/error/have-a-break.webp"
                        :alt="$t('normal.404.alt-img')"
                    />
                </div>
            </div>
        </div>
    </NuxtLayout>
</template>

<style scoped lang="scss">
    @import "@/scss/includes.scss";

    //CONTAINER
    .pdg-error-container {
        min-height: 100vh;

        color: var(--dark-blue);

        padding-top: rem(112);
        padding-bottom: rem(52);

        @media screen and (min-width: map-get($breakpoints, md)) {
            @include flex(center, $flex-direction: column);

            padding-top: rem(124);
            padding-bottom: rem(124);
        }

        //CONTAINER ROW
        .pdg-error-container-row {
            @media screen and (max-width: map-get($breakpoints, md)) {
                @include flex($flex-direction: column-reverse);
            }

            //INFO CONTAINER
            .pdg-error-info-container {
                 @include flex(center, $flex-direction: column);

                //CHIP
                .pdg-chip {
                    margin-bottom: rem(16);
                    align-self: start;
                }

                //TITLE
                .pdg-error-title,
                .pdg-error-description {
                    margin-bottom: rem(16);

                    @media screen and (min-width: map-get($breakpoints, md)) {
                        margin-bottom: rem(24);
                    }
                }

                //ARROW LINK
                .pdg-arrow-link {
                    margin-bottom: rem(32);

                    @media screen and (min-width: map-get($breakpoints, md)) {
                        margin-bottom: rem(80);
                    }
                }

                //ASTERISKS CONTAINER
                .asterisks-container {
                    width: 33.33333333%;

                    //ASTERISKS
                    .asterisks {
                        width: 100%;

                        margin: 0;
                    }
                }
            }

            //IMAGE CONTAINER
            .pdg-error-image-container {
                //IMAGE
                .pdg-error-image {
                    width: 100%;
                    height: 100%;

                    margin-bottom: rem(32);

                    object-fit: cover;

                    @media screen and (min-width: calc(map-get($breakpoints, md) + rem(0.1))) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
</style>
