<script setup lang="ts">
    import gsap from "gsap";

    defineProps<{
        hideMenu?: boolean
    }>();
    const checkIsMobile = ref<boolean>(true);
    const currentColor = ref<string | undefined>('dark');
    const getWidth = ref<number>(0);
    const openMenu = ref<boolean>(false);
    const {currentRoute} = useRouter();
    const headerMenu = ref<HTMLDivElement | null>(null);
    const viewport = ref(0);
    const globalState = useGlobalState();
    
    const toggleMenu = () => {
        openMenu.value = !openMenu.value;
        blockScroll();
    }

    const isMobile = () => {
        const breakPoint = 1024;
        const isMobile = window.matchMedia(`(max-width: ${breakPoint - 0.1}px)`);
        checkIsMobile.value = isMobile.matches;
    }

    const progressBar = (topPos: number) => {
        // https://codepen.io/thehoneycroissant/pen/oReNmp
        const progressBar = document.getElementById("progress-bar") as HTMLDivElement;
        if (progressBar) {
            let remaining = document.documentElement.scrollHeight - document.documentElement.clientHeight;
            progressBar.style.width = `${(topPos / remaining) * 100}%`;
        }
    }

    let animateItemsMenu: GSAPTimeline;

    watchEffect(() => {
        if (openMenu.value) animateItemsMenu?.play(0);
    });

    // Close the vertical menu when I change section by browsing with links
    watch(currentRoute, () => {
        if (openMenu.value && headerMenu.value?.classList.contains('is-open-menu')) {
            toggleMenu();
        }
    });

    onMounted(() => {
        if (process.client) {
            viewport.value = window.innerHeight;
            isMobile();
            animateItemsMenu = gsap.timeline({paused: true});
            const links = document.querySelectorAll('.item-link') as NodeListOf<HTMLLIElement>;
            const logo = document.getElementById('pdg-link-logo') as HTMLLinkElement;
            const items: NodeListOf<HTMLAnchorElement> = document.querySelectorAll('.wrapper-menu-vertical .item-link');

            if (links && logo) {
                const mm = gsap.matchMedia(), breakPoint = 1024;
                mm.add({
                    isDesktop: `(min-width: ${breakPoint - 0.1}px)`,
                    isMobile: `(max-width: ${breakPoint - 0.1}px)`
                },
                ({ conditions }) => {
                    const animationMenuDesktop = gsap.timeline({ delay: 1.6 }); // delay animation 
                    const { isDesktop, isMobile} = conditions as gsap.Conditions;
                    if (isDesktop) {
                            animationMenuDesktop.fromTo(links, {
                                opacity: 0,
                                y: 80,
                            }, { 
                                opacity: 1,
                                y: 0,
                                duration: 0.5,
                                x: 0,
                                stagger: {
                                    ease: "power1.inOut",
                                    amount: 0.2
                                },
                            });
                     
                        animationMenuDesktop.fromTo(logo, { 
                            opacity: 0,
                            y: 50,
                        }, {
                            y: 0,
                            opacity: 1,
                            duration: 0.5,
                        }, "-=0.7");
    
                        
                        setTimeout(() => { 
                            animationImageHover(items, true);
                        }); // swich crazy mode
                
                        //TODO globalizzare
                        animateItemsMenu.fromTo(items, {
                            xPercent: 20,
                            y: 0,
                        }, 
                        { 
                            xPercent: 0,
                            duration: 0.6,
                            stagger: 0.1,
                        });
                    } else if (isMobile) {
                        animateItemsMenu.fromTo(items,
                            {
                                y: 10,
                                xPercent: 0,
                                opacity: 0,
                            },
                            {
                                y: 0,
                                opacity: 1,
                                stagger: 0.1,
                                duration: 0.4
                            },
                        )
                    }
                    if (openMenu.value) {
                        animateItemsMenu?.play(0);
                    }
                });
            }

            const getWidthLabelButton = document.getElementById("label-button") as HTMLSpanElement;
            if (getWidthLabelButton) {
                getWidth.value = getWidthLabelButton.clientWidth;
            }
            
            window.addEventListener('resize', () => {
                viewport.value = window.innerHeight;
                isMobile();
                if (getWidthLabelButton) {
                    getWidth.value = getWidthLabelButton.clientWidth;
                }
            });

            headerMenu.value = document.getElementById('content-menu') as HTMLDivElement;
            const colorSections = document.querySelectorAll('[data-sidebar-color]') as NodeListOf<HTMLElement>;

            if(colorSections && headerMenu.value) {
                const contentMenu = headerMenu.value.querySelector('.horizontal') as HTMLDivElement;

                if (contentMenu) {
                    const getItemButton = contentMenu?.querySelector('.pdg-item-button-menu') as HTMLLIElement;
                    if (getItemButton) {
                        getItemButton.style.width = '0';
                        getItemButton.ariaHidden = "true";

                        let windowY = 0;

                        window.addEventListener('scroll', () => {

                        windowY = window.scrollY;
                        progressBar(windowY);
                        colorSections.forEach((el, index) => {
                            let positionSection = el && el.getBoundingClientRect();

                            if (index === 0) {
                                const contentVideo = el.querySelector('.pdg-video-container') as HTMLDivElement;
                                if (contentVideo) {
                                    positionSection = contentVideo.getBoundingClientRect();
                                }
                            }

                            const startChangeColor =  index === 0 || index === 1 ? 0 : viewport.value / 2 ;

                            const menuHeight = index !== 0 && index !== 1 ? 0 : (headerMenu.value?.getBoundingClientRect().height || 0) / 2;

                            const checkColor = (positionSection.top - menuHeight) < startChangeColor && 
                                            (positionSection.bottom - menuHeight) >= (index === 1 ? viewport.value / 2 : startChangeColor);

                                if (checkColor) {
                                currentColor.value = el.dataset.sidebarColor || 'dark';

                                if ( index === 0 || index === 1 ) {

                                    if ( el.dataset.sidebarColor === 'dark') {
                                        gsap.to( ".content-menu", {
                                            background: globalState.value.mode === 'normal' ? '#fff' : '#c3f73a',
                                        })

                                    } else {
                                        gsap.to( ".content-menu", {
                                            background: globalState.value.mode === 'normal' ? '#0a2843' : '#ee4266',
                                        })
                                    }
                                }
                                contentMenu.classList.add('hide-link');
                                getItemButton && (getItemButton.style.width = `${getWidth.value}px`);
                                getItemButton.ariaHidden = "false";
                            }

                            if (windowY <= (headerMenu.value?.getBoundingClientRect().height || 0)) { 
                                    gsap.to( ".content-menu", {
                                        background: globalState.value.mode === 'normal' ? '#fff' : '#ffc0d5',
                                    })

                                currentColor.value = 'dark';
                                if (contentMenu.classList.contains('hide-link')) {
                                    getItemButton && (getItemButton.style.width = "0");
                                    getItemButton.ariaHidden = "true";
                                    contentMenu.classList.remove('hide-link');
                                }
                            }
                        })
                     });
                  }
                }
            }
        }
    });

    onUnmounted(() => {
        if (process.client) {
            window.removeEventListener('resize', () => {
                isMobile();
            });
            window.removeEventListener('scroll', () => {});
        }
    });
</script>

<template>
    <nav
        class="content-menu"
        id="content-menu"
        :class="[{'is-open-menu': openMenu},
        `pdg-menu-${currentColor}`]"
    >
        <div class="pdg-container">
            <div class="wrapper-menu">
                <MenuLogoPdg :show="checkIsMobile"
                />
                <MenuItemMenu
                    v-if="!hideMenu"
                    :aria-hidden="checkIsMobile"
                    :path-img="false"
                    class="horizontal"
                    @toggle-menu="toggleMenu"
                />
                <MenuButtonActionMenu
                    v-if="!hideMenu && checkIsMobile"
                    :close-button="false"
                    @click="toggleMenu"
                    :aria-expanded="openMenu"
                    :aria-label="openMenu ? 'Close navigation' : 'Open navigation'"
                    :class="{'is-open': openMenu}"
                />
            </div>
            <MenuProgressBar
                :aria-hidden="openMenu"
            />
        </div>
    </nav>
    <div
        class="wrapper-menu-vertical pdg-container"
        id="wrapper-menu-vertical"
        :class="{'open-menu': openMenu}"
        :aria-hidden="!openMenu"
    >
        <div 
            class="content-header-vertical"
            v-if="!checkIsMobile"
        >
            <MenuLogoPdg :show="checkIsMobile"/>
            <MenuButtonActionMenu
                v-if="!hideMenu"
                :close-button="true"
                aria-label="Close navigation"
                @click="toggleMenu"
                :aria-expanded="openMenu"
            />
        </div>
        <MenuItemMenu
            v-if="!hideMenu"
            :path-img="true"
            @open-menu="toggleMenu"
            class="content-menu-vertical"
        />
    </div>
</template>

<style scoped lang="scss">
    @import "../scss/includes.scss";

    .content-menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 52; // z-index SkillsTechnologies
        padding: rem(12) 0;
        @media screen and (min-width: map-get($breakpoints, md)) {
            padding: rem(24) 0;
            z-index: 51;
        }
        
        .wrapper-menu {
            @include flex(space-between, center);

            :deep(.pdg-link-logo),
            :deep(.item-link) {
                @media screen and (min-width: map-get($breakpoints, md)) {
                    opacity: 0; // start animation
                }
            }

            .horizontal {
                @include flex($align-items: center);

                @media screen and (max-width: calc(map-get($breakpoints, md) - rem(0.1))) {
                    display: none;
                }

                :deep(.item-link) {
                    margin-bottom: 0;
                    border-bottom: 0;

                    &:not(:last-child) {
                        margin-right: rem(32);
                    }

                    .pdg-link-menu-header {
                        color: inherit;
                        transition: $transition-color;
                        white-space: nowrap;
                        @include typography(16, 500, 20, 0, uppercase, $vh: "true");

                        // label tooltip
                        .label-tooltip {
                           opacity: 0;
                           visibility: hidden;
                        }

                        &.change-mode {
                            position: relative;
                            padding: rem(10);
                            margin: rem(-10);
                            width: auto;
                            border-radius: 100%;
                            transition: color, background-color 0.2s ease-in-out;
                            @media screen and (max-width: calc(map-get($breakpoints, lg) - rem(0.1))) {
                                @include typography(24, 500, 24);
                            }
                        
                            @media screen and (min-width: map-get($breakpoints, lg)) {
                                @include typography(32, 500, 32);
                            }

                            .label-tooltip {
                                display: inline-block;
                                position: absolute;
                                top: calc(100% + rem(4)); // 4 padding link 10/2 -> 5
                                right: 50%;
                                @include typography(14, 500, 16);
                                padding: rem(8);
                                white-space: nowrap;
                                text-transform: initial;
                                transition: opacity 0.2s ease-in-out;
                                border-radius: rem(4);
                                border-top-right-radius: 0;
                             }
                        }

                        .label-link {
                            width: auto;
                            &:not(.label-tooltip) {
                                @include animated-underline();
                            }
                        }
    
                        &:hover,
                        &:focus,
                        &:focus-visible {
                            .label-link {
                                &::after {
                                    width: 100%;
                                }
                            }
                        }
                    }
                    
                    &:nth-child(-n+3) {
                        .pdg-link-menu-header {
                            transform: translateY(0);
                            opacity: 1;
                            transition-property: transform, color, opacity;
                        }
                    }
    
                    &.pdg-item-button-menu {
                        margin-right: 0;
                        width: 0;
                        overflow: hidden;
                        transition: 0.2s ease-in-out;
                        transition-property: margin-right, width;
                        visibility: hidden;

                        .pdg-open-menu-desktop {
                            font-weight: 500;
                            border: 0;
                            background: transparent;
                            padding: 0;
                            transform: translateY(-100%);
                            transition-property: transform, color;
                        }
                    }
                }

                &.hide-link {
                    :deep(.item-link) {
                        &:nth-child(-n+3) {
                            .pdg-link-menu-header {
                                transform: translateY(-100%);
                                opacity: 0;
                                width: 0;
                            }
                        }
        
                        &.pdg-item-button-menu {
                            margin-right: rem(32);
                            visibility: visible;
                        
                            .pdg-open-menu-desktop { 
                                transform: translateY(0);
                                border: rem(1) solid transparent;
                            }

                        }
                    }
                }
            }
        
        }

        @media screen and (min-width: map-get($breakpoints, md)) {
            :deep(.pdg-button-menu) {
                display: none;
            }
        }
        
        &.pdg-menu-light {
            color: var(--light);

            :deep(.content-progress-bar) {
             .progress-bar {
                    background-color: var(--green);
                }
            }

            @include hover-change-mode(--body-12, --dark, --body-15);
        }

        &.pdg-menu-dark {
            color: var(--dark);
            @include hover-change-mode(--body-3, --light, --dark);
        }

        :deep(.content-progress-bar) {
            transition: all 0.1s ease-in;
            transition-delay: 0.1s; // Hide the start bar when the menu is opened
        }

        &.is-open-menu {
            color: var(--body-13);
            background-color: transparent !important;

            :deep(.content-progress-bar) {
             opacity: 0;
             visibility: hidden;
            }
        }
    }

    .wrapper-menu-vertical {
        position: fixed;
        inset: 0;
        transform: translateY(200%) scale(1);
        transition: $transition;
        transition-property: transform;
        background-color: var(--bg-5);
        color: var(--body-13);
        overflow: hidden auto;
        @include flex($align-items: center);
        z-index: 51;

        @media screen and (min-width: map-get($breakpoints, md)) {
            transform: translateX(100%) scale(1);
        }

        :deep(.item-link) {
            opacity: 0;
        }

        .content-header-vertical {
            @media screen and (min-width: map-get($breakpoints, md)) {
                position: absolute;
                @include flex(space-between);
                top: 0;
                z-index: 3;

                left: rem(map-get($gutter-container, md));
                right: rem(map-get($gutter-container, md));
                padding: rem(24) 0;
            }
        }

        &.open-menu {
            transform: translateY(0);
            visibility: visible;

            @media screen and (min-width: map-get($breakpoints, md)) {
                transform: translateX(0);
            }
        }

        .content-menu-vertical {
            padding: rem(80) 0; // height Header
            flex: 1;
            max-height: 100vh; // capire se esiste un modo migliore 

            @media screen and (min-width: map-get($breakpoints, md)) {
                padding: rem(96) 0; // 48px margin-bottom
            }

            @media screen and (min-width: map-get($breakpoints, lg)) {
                padding: rem(136) 0;
            }
        }
    }
</style>