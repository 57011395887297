import gsap from "gsap";
import SplitType from "split-type";
import { Power1 } from "gsap";

type TextAnimationProps = {
    tl?: GSAPTimeline | undefined;
    split?: boolean;
    container: string;
    elements: (HTMLElement | string)[];
    from?: GSAPTweenVars | undefined;
    to: GSAPTweenVars;
    keyframe?: string | number | undefined;
};

type ChipAnimationProps = {
    tl?: GSAPTimeline | undefined;
    container: string;
    target?: HTMLElement;
    from?: GSAPTweenVars | undefined;
    to?: GSAPTweenVars;
    keyframe?: string | number | undefined;
};

type ImageAnimationProps = {
    tl?: GSAPTimeline | undefined;
    container: string;
    keyframe?: string | number | undefined;
};

type SlideAnimationProps = {
    tl?: GSAPTimeline | undefined;
    container: string;
    elements: (HTMLElement | string)[];
    keyframe?: string | number | undefined;
};

export const textAnimation = ({
    tl = undefined,
    split = false,
    container,
    elements,
    from,
    to,
    keyframe,
}: TextAnimationProps) => {
    document.fonts.ready.then(() => {
        setTimeout (() => {
            split &&
                elements.map(
                    (element) => {
                        const text = new SplitType(`${container} ${element}`, {
                            types: "lines, words",
                            lineClass: "pdg-line",
                        })
                        return text
                    }
                );
            const g = tl || gsap;
    
            const selector = `${container} .pdg-line .word`;
    
            return from ? g.fromTo(selector, from, to, keyframe) : g.to(selector, to, keyframe);
        }, 0)
      });
};

export const chipAnimation = ({
    tl = undefined,
    container,
    
    from,
    to,
    target,
    keyframe,
}: ChipAnimationProps) => {
    setTimeout (() => {
        const g = tl || gsap;
        const chip =
            target || (document.querySelector(`${container} .pdg-chip`) as HTMLSpanElement);
    
        g.fromTo(
            chip,
            {
                width: 0,
                opacity: 0,
                ...from,
            },
            {
                width: "auto",
                opacity: 1,
                ease: Power1.easeIn,
                duration: 0.5,
                ...to,
            },
            keyframe
        );
    },0)
};

export const imageAnimation = ({
    tl = undefined,
    container,
    keyframe,
}: ImageAnimationProps) => {
    const timeline = tl || gsap.timeline();

    const open = () =>
        //SHOW ANIMATED IMAGE COLORED OVERLAY
        timeline
            ?.to(
                `${container} .pdg-animated-image-overlay`,
                {
                    opacity: 1,
                    duration: 0,
                },
                0
            )
            .to(
                `${container} .pdg-animated-image-overlay`,
                {
                    y: 0,
                    stagger: 0.1,
                    duration: 0.3,
                },
                keyframe
            )
            //SHOW ANIMATED IMAGE
            .to(`${container} .pdg-animated-image`, {
                opacity: 1,
                duration: 0,
            })
            //HIDE ANIMATED IMAGE COLORED OVERLAY
            .to(
                `${container} .pdg-animated-image-overlay`,
                {
                    y: "-100%",
                    duration: 0.3,
                },
                "end"
            )
            //SCALE ANIMATED IMAGE
            .to(
                `${container} .pdg-animated-image`,
                {
                    scale: 1,
                    duration: 0.3,
                },
                "end"
            )
            .to(`${container} .pdg-animated-image-overlay`, {
                y: "100%",
                opacity: 0,
                duration: 0,
            });

    const close = () =>
        timeline
            //HIDE ANIMATED IMAGE
            ?.to(`${container} .pdg-animated-image`, {
                yPercent: -100,
                duration: 0.3,
            })
            //RESET ANIMATED IMAGE
            .to(`${container} .pdg-animated-image`, {
                opacity: 0,
                yPercent: 0,
                scale: 1.1,
                duration: 0,
            });

    return { open, close };
};

export const animationDelay = (tl: GSAPTimeline, container: HTMLElement) =>
    setTimeout(() => container.getBoundingClientRect().y <= 0 && tl.delay(1.6));
    
/*TODO da aggiungere i fix richiesti da Nora questo cidice sarà da 
  * migliorare perché bisogna mettere le animazioni fatte in SkillsTechnologies
*/
export const animationImageHover = (items: NodeListOf<HTMLElement>, isMenu?: boolean) => {
  items.forEach((el) => {
    const image = (el as HTMLImageElement).querySelector(".pdg-img");
    const getLink = (el as HTMLImageElement).querySelector(".pdg-link-menu-header");

    if (image && !getLink?.classList.contains("active-link")) {
        (el).addEventListener("mouseenter", () => {
            gsap.to(image, { autoAlpha: 1 });
        });

        (el).addEventListener("mouseleave", () => {
            gsap.to(image, { autoAlpha: 0 });
        });

        let currentPosition = "";
        (el).addEventListener("mousemove", (e) => {
            const menuWidth = (el).offsetWidth;
            const mouseOffsetX = e.offsetX;
            const offset = 100;

            const imageRight = mouseOffsetX + offset + (isMenu ? (image?.clientWidth / 2) : 0);
            const imageLeft = mouseOffsetX - offset - (image?.clientWidth / 2);
            let offsetImage = mouseOffsetX + offset + (image?.clientWidth);

            const fade = () => gsap.fromTo(
            image,
                { opacity: 0 },
                { opacity: 1, duration: 0.8, ease: "power2.inOut" }
            );

            (menuWidth >= offsetImage && currentPosition !== 'right') || 
            (menuWidth <= offsetImage && currentPosition !== 'left') && fade();
            currentPosition = menuWidth >= offsetImage ? "right" : "left"
            gsap.set(image, { x: menuWidth >= offsetImage ? imageRight : imageLeft });
        });
    }
  });
};