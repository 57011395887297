<script setup lang="ts">
    defineProps<{
        title: string;
        description: string;
        url: string;
        type: string;
        keywords?: string;
    }>();

    const urlReq = useRequestURL()
</script>

<template>
    <Head>
        <!-- TIILE -->
        <Title v-if="title">{{ title }}</Title>

        <!-- META TAGS -->
        <Meta name="description" :content="description" v-if="description" />
        <Meta name="keywords" :content="keywords" v-if="keywords" />

        <!-- OG TAGS -->
        <Meta property="og:type" :content="type" v-if="type" />
        <Meta property="og:url" :content="url" v-if="url" />
        <Meta property="og:title" :content="title" v-if="title" />
        <Meta property="og:description" :content="description" v-if="description" />
        <Meta property="og:image" :content="`https://paradigma.me/assets/img/share-img/facebook.jpg`" />
        <Meta property="og:image:type" content="image/jpeg"/>
        <Meta property="og:image:width" content="1200" /> 
        <Meta property="og:image:height" content="630" />
        <Meta property="og:site_name" content="Paradigma" />

        <!-- OG TAGS TW -->
        <Meta name="twitter:card" content="summary"/>
        <Meta name="twitter:site" content="@Paradigma_Inn"/>
        <Meta property="twitter:title" :content="title" v-if="title" />
        <Meta property="twitter:description" :content="description" v-if="description" />
        <Meta property="twitter:image" :content="`https://paradigma.me/assets/img/share-img/twitter.jpg`" />

        <!-- CANONICAL TAG -->
        <Link rel="canonical" :href="url" v-if="url" />
    </Head>
</template>
